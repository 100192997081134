import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import get from 'lodash/get';
import './about-page.scss';
import { IAboutPage, IAboutPageNode } from '../queries-intefaces/iabout-page';
import loadable from '@loadable/component'

export interface ICountryPageTemplate {
  data: IAboutPage;
  location: any;
}

class AboutPageTemplate extends React.Component<ICountryPageTemplate, any> {
  render() {
    const Layout = loadable(() => import ('../components/layout'));
    const FounderSection = loadable(() => import ('../components/about-page/founder-section/founder-section'));
    const HeaderSection = loadable(() => import ('../components/about-page/header-section/header-section'));
    const ContactSection = loadable(() => import ('../components/about-page/contact-section/contact-section'));

    const dataNode: IAboutPageNode = this.props.data.allContentfulAboutPageFull?.nodes[0];
    const siteTitle = this.props.data?.site?.siteMetadata.title;
    const [footerData] = get(this, 'props.data.allContentfulFooterSection.nodes');
    const [headerData] = get(this, 'props.data.allContentfulHeaderSection.nodes');
    return (
      <Layout className='orange-style' footer={footerData} header={headerData}>
        <div style={{ background: '#fff' }} className="">
        <Helmet title={`${dataNode.title} | ${siteTitle}`} />
          <HeaderSection
            title={dataNode?.title}
            subTitle={dataNode?.subTitle}
          />
          <Helmet title={`${siteTitle}`} />
           <FounderSection
            founder01={dataNode?.founder01}
            founder02={dataNode?.founder02}
          />
          <ContactSection
            disclaimerLeftSide={dataNode?.disclaimerLeftSide}
            disclaimerRightSide={dataNode?.disclaimerRightSide}
            contactEmail={dataNode?.contactEmail}
          />
        </div>
      </Layout>
    );
  }
}
export default AboutPageTemplate;

export const aboutPageQuery = graphql`
  query aboutPageQuery {
    allContentfulAboutPageFull(limit: 1) {
      nodes {
        title
        subTitle
        contactEmail
        founder01 {
          childMarkdownRemark {
            html
            rawMarkdownBody
          }
        }
        founder02 {
          childMarkdownRemark {
            html
            rawMarkdownBody
          }
        }
        disclaimerLeftSide {
          childMarkdownRemark {
            html
          }
        }
        disclaimerRightSide {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    allContentfulFooterSection(limit: 1){
      nodes {
        icon {
          id
          fluid {
            src
            base64
          }
          file {
            url
          }
        }
        description {
          childMarkdownRemark{
            html
          }
        }
        copyright {
          childMarkdownRemark{
            html
          }
        }
        socialMediaLinks{
          title
          linkUrl
          showLink
        }
        links{
          title
          linkUrl
          showLink
        }
      }
    }
    allContentfulHeaderSection(limit: 1){
      nodes {
        title
        leftLinks{
          title
          linkUrl
          showLink
        }
        rightLinks{
          title
          linkUrl
          showLink
        }
      }
    }
  }
`;
